.button {
  display: inline-block;
  border-radius: 0.4em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  border: 1px solid;
  outline: none;
  cursor: pointer;
  text-align: center;

  &:disabled, &.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  &-primary {
    background: $color-primary;
    color: $color-text-contrast;
    border-color: $color-primary;

    @include hoverable((background-color, border-color), $color-tertiary);
  }

  &-secondary {
    background: none;
    color: $color-primary;
    border-color: $color-primary;

    @include hoverable((color, border-color), $color-tertiary);
  }

  &-primary#{&}-contrast {
    background: $color-background-basic;
    border-color: $color-text-contrast;

    @include hoverable((background-color, border-color), $color-background-darker);
  }

  &-secondary#{&}-contrast {
    background: none;
    color: $color-text-contrast;
    border-color: $color-text-contrast;

    @include hoverable((color, border-color), $color-background-darker);
  }

  &-large {
    padding: 15px 50px;
    font-size: 24px;
    line-height: 30px;
    box-shadow: 0 0 25px 5px rgba(0, 0, 0, 0.1);

    @include lt(lg) {
      padding: 13px 30px;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &-medium {
    padding: 13px 30px;
    font-size: 20px;
    line-height: 24px;

    @include lt(lg) {
      padding: 10px 30px;
      font-size: 16px;
    }
  }

  &-small {
    padding: 10px 30px;
    font-size: 16px;
    line-height: 24px;
  }

  &-xsmall {
    padding: 4px 20px;
    font-size: 14px;
    line-height: 20px;
  }
}
