$paper-padding-desktop: (50px 40px 60px);
$paper-padding-mobile: (40px 30px);

@mixin paper($padded: false) {
  background: $color-background-basic;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 20px;

  @if $padded {
    padding: $paper-padding-desktop;

    @include lt(md) {
      padding: $paper-padding-mobile;
    }
  }
}

.paper {
  @include paper;

  &-padded {
    @include paper(true);
  }

  header {
    text-align: center;
  }

  .actions {
    margin-top: 10px;
  }
}
