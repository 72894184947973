$color-primary: #F51D86;
$color-secondary: #22A7F9;
$color-tertiary: #7B61FF;
$color-supplement: #E733AC;
$color-supplement-light: #FFD8ED;

$color-text-primary: #000;
$color-text-secondary: #787878;
$color-text-contrast: #fff;
$color-text-link: #F51D86;
$color-text-placeholder: #C9C5C3;
$color-text-faded: #b1b1b1;

$color-success: #2FBA18;
$color-error: #D80505;

$color-background-basic: #fff;
$color-background-darker: #f2f2f2;
$color-border: #DDD;

$background-gradient: linear-gradient(106.57deg, #60007E 1.33%, #FB63C2 95.54%);

$breakpoints: (
  xs: 480,
  sm: 600,
  md: 780,
  lg: 960,
  xl: 1240
);

$spacings: (
  xs: 10px,
  sm: 20px,
  md: 30px,
  lg: 50px,
  xl: 60px,
);
