ul,
ol {
  margin-left: 0;
  padding-left: 1.25em;
}

ul {
  list-style: '·  ';
}

.list-sparse {
  margin-bottom: -20px;

  li {
    margin-bottom: 20px;
  }
}

.list-checkmark {
  @extend .list-sparse;
  list-style: none;
  padding-left: 0;

  li {
    @include checked-point;
  }
}

.list-illustrated {
  list-style: none;
  padding-left: 0;

  figure {
    @include stack;
    align-items: center;
  }

  figcaption {
    font-weight: bolder;
  }
}

.list {
  list-style: none;
  display: flex;
  flex-flow: column nowrap;
  padding: 15px;
  gap: map-get($spacings, xs);
  text-align: center;
  align-items: stretch;

  a, .link {
    color: $color-text-primary;
    padding: 0 map-get($spacings, md);
    display: block;
  }
}
