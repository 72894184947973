.table {
  max-width: 770px;
  margin: 0 auto;

  .row {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    padding: 0 0 map-get($spacings, md);
    column-gap: map-get($spacings, md);
    row-gap: map-get($spacings, xs);

    & + .row {
      border-top: 1px solid #E5E5E5;
      padding-top: map-get($spacings, md);
    }

    &.editing {
      row-gap: map-get($spacings, md);

      .actions {
        width: 100%;
        justify-content: flex-end;

        > * {
          @include lt(sm) {
            flex: 1 1 auto;
          }
        }
      }
    }
  }

  .editor-row {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    column-gap: map-get($spacings, md);
    row-gap: map-get($spacings, xs);
    flex: 1 1 auto;
  }

  .cell {
    &.label {
      width: 150px;
      font-weight: bolder;
    }

    &.editor {
      flex: 1 1 auto;

      .input-container {
        @include gt(sm) {
          max-width: 370px;
        }
      }

      @include lt(sm) {
        width: 100%;
      }
    }

    &.actions {
      display: flex;
      column-gap: map-get($spacings, md);
      justify-content: flex-end;
      flex: 1 1 auto;
    }

    &.value {
      flex: 1 1 auto;

      @include lt(sm) {
        order: 2;
        width: 100%;
      }
    }
  }
}
