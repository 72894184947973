.payment-methods {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: map-get($spacings, md);
  grid-row-gap: 40px;

  @include lt(xs) {
    grid-column-gap: map-get($spacings, sm);
    grid-row-gap: map-get($spacings, md);
  }

  .footer {
    grid-column: 1 / span 2;
    text-align: center;
  }
}

.payment-method {
  @include paper;

  display: block;
  position: relative;
  padding: 0 0 60%;

  &::before {
    $padding: map-get($spacings, sm);

    display: block;
    content: '';
    position: absolute;

    left: $padding;
    right: $padding;
    top: $padding;
    bottom: $padding;

    @include lt(xs) {
      $padding: map-get($spacings, xs);

      left: $padding;
      right: $padding;
      top: $padding;
      bottom: $padding;
    }

    background: center center no-repeat;
    background-size: contain;
  }

  &-paypal::before {
    background-image: url(../../assets/images/payment-method-paypal.svg);
  }

  &-wayforpay::before {
    background-image: url(../../assets/images/payment-method-wayforpay.png);
  }
}
