.avatar {
  $size: 2em;

  color: $color-background-basic;
  text-decoration: none;
  background: #579AFF;
  display: inline-block;
  width: $size;
  line-height: $size;
  text-align: center;
  border-radius: 50%;
}
