$reviews-sizes: (
        md: 200px,
        lg: 300px,
);

$border-width: 6px;

.reviews {
  padding: 0 50px;
  overflow: hidden;

  .review {
    img {
      margin: 0 auto;

      & + * {
        margin-top: 50px;
      }
    }

    figcaption {
      font-weight: bolder;

      & + * {
        margin-top: 20px;
      }
    }

    blockquote {
      @extend .text-primary;

      text-align: start;
    }
  }


  &-rounded {
    .review {
      img {
        border-radius: 50%;
      }
    }
  }

  &-decorated {
    .review {
      img {
        border: $border-width solid #99d6ff;
      }
    }
  }

  &-contrast {
    .review {
      img {
        border-color: $color-background-basic;
      }
    }
  }


  @each $name, $size in $reviews-sizes {
    &-size-#{$name} {
      &:not(.reviews-auto-height) {
        .owl-nav {
          top: $size / 2;
        }
      }

      .review {
        img {
          width: $size;
        }
      }

      &.reviews-decorated {
        .review {
          img {
            width: $size + 2 * $border-width;
          }
        }
      }
    }
  }
}
