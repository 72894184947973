.section {
  padding: 40px 0 50px;

  @include gt(lg) {
    padding: 80px 0 100px;
  }

  .container,
  .content {
    @include stack(lg);
  }

  &-header {
    @include stack;
    text-align: center;
  }

  &-stripe {
    padding: 15px 0;
    background: $background-gradient;

    p {
      justify-content: center;
    }
  }

  &-center {
    text-align: center;
  }

  &-contrast {
    color: $color-text-contrast;
  }
}

.section-inner-page {
  background: $background-gradient;

  .container {
    max-width: 610px;
  }

  .paper {
    padding: 30px 30px 40px;

    @include gt(lg) {
      padding: 50px 40px 60px;
    }
  }
}

.section-signup {
  @extend .section-inner-page;

  @include gt(lg) {
    min-height: 980px;
    background: url(../../assets/images/bg-signup.png) calc(30% - 300px) bottom no-repeat, $background-gradient;
  }

  &-payment {
    @include gt(lg) {
      background: url(../../assets/images/bg-payment.png) calc(60% + 400px) calc(100% - 5px) no-repeat, $background-gradient;
    }
  }

  &-thanks {
    @include gt(lg) {
      background: url(../../assets/images/bg-thanks.png) calc(30% - 350px) bottom no-repeat, $background-gradient;
    }
  }
}
