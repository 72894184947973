.owl-carousel {
  .owl-stage-outer {
    z-index: 1;
    padding: 15px;
    margin: -15px;
  }

  .owl-stage {
    display: flex;
    align-items: stretch;

    .owl-item {
      display: flex;
      flex-flow: column;
    }
  }

  .paper {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  }

  .owl-nav {
    font-size: 32px;
    height: 32px;
    color: $color-primary;
    position: absolute;
    top: 50%;
    left: -40px;
    right: -40px;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    z-index: 0;

    .owl-next,
    .owl-prev {
      @include material-icons;
      display: block;
      position: relative;
      z-index: 2;

      cursor: pointer;
      @include hoverable(color, $color-tertiary);

      &.disabled {
        opacity: 30%;
        cursor: default;
      }

      span {
        display: none;
      }

      &::after {
        width: 32px;
        height: 32px;
        display: block;
      }
    }

    .owl-next {
      &::after {
        content: 'chevron_right';
      }
    }

    .owl-prev {
      &::after {
        content: 'chevron_left';
      }
    }
  }
}
