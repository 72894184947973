.checkbox {
  color: $color-text-primary;

  input[type="checkbox"] {
    display: none;
  }

  .input-container.error & {
    .checkbox-icon {
      color: $color-error;
    }
  }

  &-icon {
    @include material-icons;
    color: $color-primary;
    vertical-align: -0.25em;
    font-size: 1.2em;
    margin-right: 5px;

    &::after {
      content: 'check_box_outline_blank';
    }

    input[type="checkbox"]:checked + & {
      &::after {
        content: 'check_box';
      }
    }
  }
}
