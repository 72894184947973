.icon-button {
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;

  @each $name in (instagram, email, phone) {
    &-#{$name} {
      background-image: url(../../assets/images/icon-#{$name}.svg);
    }
  }
}
