.actions {
  display: flex;
  flex-flow: row wrap;
  row-gap: map-get($spacings, sm);
  column-gap: map-get($spacings, md);
  justify-content: center;
  text-align: center;
  align-items: center;

  @include lt(sm) {
    flex-flow: column nowrap;
    align-items: stretch;
  }

  .error {
    flex: 0 1 100%;
  }
}
