:root {
  color-scheme: light;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

[hidden] {
  display: none !important;
}

footer,
header,
nav,
section {
  display: block;
}

nav ul,
nav ol {
  list-style: none;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
}

p {
  margin: 0;
  padding: 0;
}
