@import './styles/index';

:root {
  --plyr-color-main: #{$color-supplement};
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

@import 'app/loader/loader.component';
