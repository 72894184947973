.columns {
  display: grid;
  align-items: self-start;

  @include columns(map-get($spacings, md));

  @each $name, $spacing in $spacings {
    &-gap-#{$name} {
      @include columns($spacing);
    }

    &-row-gap-#{$name} {
      row-gap: $spacing;
    }
  }

  &-stretch {
    align-items: stretch;
  }
}
