.recipe {
  @include stack(sm);
  list-style: none;
  padding-left: 0;

  .recipe {
    margin-top: 15px;
    gap: 15px;
    padding-left: map-get($spacings, sm);

    .recipe-product {
      &-name {
        font-weight: inherit;
      }
    }
  }

  &-product {
    display: flex;
    justify-content: space-between;
    gap: map-get($spacings, xs);

    &-name {
      font-weight: bolder;

      &.complex {
        @include stack(sm, row);

        color: $color-primary;
        cursor: pointer;

        &:hover, &:focus {
          text-decoration: none;

          span {
            text-decoration: underline;
          }
        }

        &::before {
          @include material-icons;
          content: 'menu_book';
          font-size: 24px;
          transform: translateY(-2px);
        }
      }
    }

    &-quantity {
      color: $color-text-faded;
      text-align: end;
      min-width: 45px;
    }
  }
}
