.text-logo {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  color: #FFC0E2;
  font-size: 30px;
  line-height: 45px;
  display: flex;
  flex-flow: column nowrap;
  row-gap: 10px;

  .text-logo-subtitle {
    display: block;
    text-transform: uppercase;
    color: $color-text-contrast;
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;

    @include lt(sm) {
      font-size: 12px;
    }
  }

  @include lt(sm) {
    font-size: 20px;
    line-height: 25px;
  }
}