@mixin stack($spacing: md, $direction: column) {
  display: flex;
  flex-flow: $direction nowrap;
  gap: map-get($spacings, $spacing);
}

.stack {
  @include stack;

  @each $name, $spacing in $spacings {
    &-#{$name} {
      gap: $spacing;
    }
  }

  &-horizontal {
    flex-flow: row nowrap;
  }

  &-center {
    align-items: center;
  }
}
