$accordion-padding-desktop: 30px 40px;
$accordion-padding-mobile: 20px 30px;

.accordion {
  @include stack;

  &-item {
    @include paper;
    display: block;
  }

  &-header {
    font-weight: bolder;
    padding: $accordion-padding-desktop;
    @extend .text-important;

    cursor: pointer;
    display: grid;
    grid-gap: map-get($spacings, sm);
    overflow: hidden;
    grid-template-columns: auto max-content;
    position: relative;

    &::before,
    &::after {
      @include material-icons;
      color: $color-primary;
      content: "remove";
      height: 40px;
      width: 40px;
      font-size: 40px;
      margin: -6px;

      @include lt(lg) {
        font-size: 36px;
        width: 36px;
        height: 36px;
      }
    }

    &::before {
      position: absolute;
      right: nth($accordion-padding-desktop, 2);
      top: nth($accordion-padding-desktop, 1);

      @include lt(md) {
        right: nth($accordion-padding-mobile, 2);
        top: nth($accordion-padding-mobile, 1);
      }
    }

    &::after {
      transform: rotate(-90deg);
      transition: transform .2s;
    }

    &.open {
      &::after {
        transform: rotate(0deg);
      }
    }

    @include lt(md) {
      padding: $accordion-padding-mobile;
    }
  }

  &-main {
    @include stack(xs);
    @extend .text-primary;
    padding: $accordion-padding-desktop;
    padding-top: 0;
    overflow: hidden;

    @include lt(md) {
      padding: $accordion-padding-mobile;
      padding-top: 0;
    }
  }
}
