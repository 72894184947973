.form {
  @extend .text-primary;
  display: grid;
  grid-gap: map-get($spacings, md);
  grid-template-columns: auto 1fr;
  align-items: baseline;

  @include lt(sm) {
    $compensation: map-get($spacings, md) - map-get($spacings, xs);

    grid-template-columns: auto;
    grid-row-gap: map-get($spacings, xs);
    margin-bottom: -$compensation;

    .input-container {
      margin-bottom: $compensation;
    }
  }

  label {
    font-weight: bolder;
  }

  .input-checkbox-container {
    @include gt(sm) {
      grid-column: 1 / span 2;
    }
  }

  #recaptcha {
    position: absolute;

    .grecaptcha-badge {
      box-shadow: none !important;
    }
  }
}
