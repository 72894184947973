.input {
  background: $color-background-basic;
  border: 1px solid $color-border;
  border-radius: 5px;
  padding: 10px 15px;
  width: 100%;

  @extend .text-primary;

  &-container {
    display: flex;
    flex-flow: column;
    row-gap: map-get($spacings, xs);

    &.error {
      .input {
        border-color: $color-error;
      }

      .input-hint:not(.error) {
        display: none;
      }
    }
  }

  &-hint {
    @extend .text-secondary;

    &:empty {
      display: none;
    }
  }

  &-password-container {
    position: relative;
  }

  &-password {
    padding-right: 50px;

    + .password-visibility-toggle {
      position: absolute;
      width: 24px;
      height: 24px;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      overflow: hidden;

      &::after {
        color: $color-primary;
      }
    }

    &[type="password"] + .password-visibility-toggle {
      &::after {
        content: 'visibility_off';
      }
    }

    &[type="text"] + .password-visibility-toggle {
      &::after {
        content: 'visibility_on';
      }
    }
  }

  &::placeholder {
    color: $color-text-placeholder;
  }
}

select.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../../assets/images/arrow.svg) calc(100% - 10px) center no-repeat;
  padding-right: 33px;
}
