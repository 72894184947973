$plan-header-padding-desktop: 30px 40px;
$plan-header-padding-mobile: 20px 30px;
$plan-content-horizontal-padding-desktop: 20px;
$plan-content-horizontal-padding-mobile: 20px;
$plan-footer-vertical-padding-desktop: 40px;
$plan-footer-vertical-padding-mobile: 40px;

.plans {
  overflow: hidden;

  &.owl-loaded {
    cursor: move;
  }

  &:not(.owl-loaded) {
    display: flex;
    column-gap: 30px;
    padding: 20px;
  }

  .owl-stage-outer {
    padding: 20px 0;
  }

  .owl-stage {
    display: flex;
    align-items: stretch;

    &::after {
      display: none;
    }

    .owl-item {
      display: flex;
    }
  }

  .plan {
    @include paper;
    @extend .text-primary;

    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;

    header {
      color: $color-text-contrast;
      padding: $plan-header-padding-desktop;
      border-radius: 20px 20px 0 0;
      text-align: center;

      h1 {
        font-weight: bolder;
      }

      s {
        @extend .h3;
        display: block;
        color: #E5E5E5;
      }
    }

    main {
      flex: 1 0 auto;
      padding: 30px $plan-content-horizontal-padding-desktop;

      h2 {
        margin-top: 20px;
        margin-bottom: 10px;
        font-weight: bolder;

        @include checked-point;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    footer {
      text-align: center;
      padding: 0 $plan-content-horizontal-padding-desktop $plan-footer-vertical-padding-desktop;
      border-radius: 0 0 20px 20px;
    }

    &-basic {
      header {
        background: #F47CC1;
      }
    }

    &-premium {
      $premium-plan-border: 2px solid #E733AC;

      header {
        background: linear-gradient(91.99deg, #60007E 0.71%, #E733AC 101.59%);
      }

      main {
        border: $premium-plan-border;
        border-top: 0;
        border-bottom: 0;
      }

      footer {
        border: $premium-plan-border;
        border-top: 0;
      }
    }

    &-premium-plus {
      header {
        background: #7AC9FF;
      }
    }

    @include lt(md) {
      header {
        padding: $plan-header-padding-mobile;
      }

      main, footer {
        padding-left: $plan-content-horizontal-padding-mobile;
        padding-right: $plan-content-horizontal-padding-mobile;
      }

      footer {
        padding-bottom: $plan-footer-vertical-padding-mobile;
      }
    }
  }
}
