/// Mixin to manage responsive breakpoints
/// @author Kitty Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @param {'min'|'max'} $direction - Breakpoint direction ('min' or 'max')
/// @require $breakpoints
@mixin breakpoint($breakpoint, $direction) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    $size: map-get($breakpoints, $breakpoint);
    $width: $size;

    @if ($direction == 'max') {
      $width: $size - 1;
    }
    // Prints a media query based on the value
    @media screen and (#{$direction}-width: #{$width}px) {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin lt($breakpoint) {
  @include breakpoint($breakpoint, 'max') {
    @content;
  };
}

@mixin gt($breakpoint) {
  @include breakpoint($breakpoint, 'min') {
    @content;
  };
}

@mixin columns($gap) {
  $sizes: 2, 3, 4, 5, 6;

  & {
    display: flex;
    flex-flow: row wrap;
    column-gap: $gap;
    flex: 0 0 auto;

    > * {
      width: 100%;
    }
  }

  @each $size in $sizes {
    $format: calc((100% - #{$size - 1} * #{$gap}) / #{$size});

    &.columns-#{$size} > * {
      width: $format;
    }

    @each $name, $value in $breakpoints {
      @include gt($name) {
        &.columns-#{$name}-#{$size} > * {
          width: $format;
        }
      }
    }
  }
}

@each $breakpoint, $_ in $breakpoints {
  @each $direction in ('lt', 'gt') {
    .hidden-#{$direction}-#{$breakpoint} {
      @if $direction == 'lt' {
        @include lt($breakpoint) {
          display: none !important;
        }
      }
      @if $direction == 'gt' {
        @include gt($breakpoint) {
          display: none !important;
        }
      }
    }
  }
}

@mixin hoverable($color-props, $hover-color) {
  $transitions: ();
  @each $color-prop in $color-props {
    $transitions: append($transitions, #{$color-prop} .2s, 'comma');
  }

  transition: $transitions;

  &:not(:disabled) {
    &:hover, &:focus {
      @each $color-prop in $color-props {
        #{$color-prop}: $hover-color;
      }
    }
  }
}

@mixin checked-point {
  padding-left: 40px;
  min-height: 30px;

  background: url(../../assets/images/icon-checkbox.svg) left 2px no-repeat;
  background-size: 24px;
}
