$headers-secondary-sizes: (
  h1-secondary: (24px 36px),
  h2-secondary: (20px 30px),
  h3-secondary: (16px 24px),
  h4-secondary: (14px 22px),
);

$text-sizes: (
  text-important: (20px 30px),
  text-primary: (16px 24px),
  text-secondary: (14px 22px),
  text-tertiary: (11px 14px),
);

$header-sizes: (
  h1: (40px 60px),
  h2: (30px 45px),
  h3: (20px 30px),
  h4: (16px 24px),
);

$fonts: (
  headers: (400 Bitter),
  headers-secondary: (500 Montserrat),
  text: (500 Montserrat),
);

@each $font-type, $font-sizes in (headers: $header-sizes, headers-secondary: $headers-secondary-sizes, text: $text-sizes) {
  $font-params: map-get($fonts, $font-type);
  $font-weight: nth($font-params, 1);
  $font-family: nth($font-params, 2);

  @each $name, $sizes in $font-sizes {
    .#{$name} {
      font-family: $font-family;
      font-weight: $font-weight;

      $font-size: nth($sizes, 1);
      $line-height: nth($sizes, 2);

      @if $name == text-secondary or $name == text-supplement {
        font-size: $font-size;
        line-height: $line-height;
      } @else {
        $smaller-sizes: map-get-next($font-sizes, $name, $sizes);

        $smaller-font-size: nth($smaller-sizes, 1);
        $smaller-line-height: nth($smaller-sizes, 2);

        font-size: $smaller-font-size;
        line-height: $smaller-line-height;

        @include gt(lg) {
          font-size: $font-size;
          line-height: $line-height;
        }
      }
    }
  }
}

@each $header in (h1 h2 h3 h4) {
  #{$header} {
    @extend .#{$header};

    &.secondary {
      @extend .#{$header}-secondary;
    }
  }
}

body {
  @extend .text-primary;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: -0.015em;
  font-weight: 500;
  color: $color-text-primary;

  &.landing {
    @extend .text-important;
  }
}

.header-accent {
  font-family: "Montserrat", serif;
  color: $color-primary;
  font-size: 120px;
  line-height: 120px;
  font-weight: bolder;
}

.text-center {
  text-align: center;
}
