.tabs {
  display: flex;
  justify-content: center;

  &:not(.tabs-buttons) {
    border-bottom: 1px solid #E5E5E5;

    .tab {
      padding: 20px 40px;

      @include lt(sm) {
        padding-left: 20px;
        padding-right: 20px;
      }

      &.active {
        border-bottom: 1px solid $color-supplement;
        margin-bottom: -1px;
        color: $color-supplement;
      }
    }
  }

  .tab {
    display: block;
    cursor: pointer;
    color: $color-text-primary;

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  &-scroll-container {
    overflow-y: auto;
    padding-top: 20px;
    margin-top: -20px;
  }

  &-buttons {
    gap: 30px;
    min-width: min-content;

    .tab {
      white-space: nowrap;
      padding: 0.5em 1em;
      border: 1px solid $color-supplement;
      border-radius: 5px;
      color: $color-supplement;

      &.viewed {
        color: $color-supplement;
        background: $color-supplement-light;
        border-color: $color-supplement-light;
        position: relative;

        &::after {
          @include material-icons;
          content: 'check_circle';

          display: flex;
          justify-content: center;
          align-items: center;

          position: absolute;
          left: 50%;
          top: -2px;
          transform: translate(-50%, -50%);

          font-size: 24px;
          width: 18px;
          height: 18px;

          background: $color-background-basic;
          border-radius: 50%;
        }

        &.active::after {
          color: darken($color-supplement, 10%);
        }
      }

      &.active {
        color: $color-text-contrast;
        background: $color-supplement;
        border-color: $color-supplement;
      }
    }
  }
}
