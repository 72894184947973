.order-details {
  @include stack(xs);
  width: 100%;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .strong {
    font-weight: bolder;
  }

  .plan {
    font-weight: bolder;
    display: flex;
    justify-content: space-between;
    column-gap: map-get($spacings, md);
    row-gap: map-get($spacings, xs);

    .price {
      white-space: nowrap;

      s {
        font-weight: normal;
      }
    }

    @include lt(sm) {
      flex-flow: column;
    }
  }
}
