a, .link {
  cursor: pointer;
  text-decoration: none;
  color: $color-text-link;

  &:hover,
  &:focus {
    &:not(.button) {
      text-decoration: underline;
    }
  }
}
