.popup {
  @include paper;

  position: absolute;
  z-index: 10;

  &-form {
    padding: 20px 30px 30px;

    @include gt(md) {
      padding: 30px 40px 40px;
    }

    h2 {
      font-weight: bolder;
    }

    .actions {
      $font-params: map-get($text-sizes, text-primary);
      font-size: nth($font-params, 1);
      line-height: nth($font-params, 2);
      justify-content: space-around;
    }
  }
}
