@import "./variables";
@import "./functions";
@import "./mixins";
@import "./reset";
@import "./typography";
@import "./ui";

// Basic layout
body {
  background: $color-background-basic;

  &.has-open-popup {
    overflow: hidden;
  }
}

.container {
  width: 100%;
  max-width: 1210px;
  margin: 0 auto;
  padding: 0 20px;
}

